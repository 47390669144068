import React from "react"
import { FC, useState, useEffect } from "react"
import { store } from "../../store"
import { fetchQuotesFromFirestore } from "../../logic/firestoreHelper"
import { Link } from "react-router-dom"

const kindleQuoteTmp = {
    book: "",
    content: "",
    position: "",
    guid: "",
}

const random = (length: number) => {
    const r = Math.floor(Math.random() * Math.floor(length))
    console.log("Picking random note index: ", r)
    return r
}

const fetchKindleQuotes = async () => {
    try {
        const kindleQuotes = store.getState().manifest?.kindleQuotes
        console.log("fetchKindleQuotes")
        if (kindleQuotes) {
            console.log("Fetching from perisisted kindleQuotes store")
            return kindleQuotes[random(kindleQuotes.length)]
        } else {
            console.log("???")
            const kindleQuotes = await fetchQuotesFromFirestore("clips")
            const asdf = random(kindleQuotes.length)
            return kindleQuotes[asdf]
        }
    } catch {
        console.log("kindleQuotes not initialised. Fetching kindleQuotes")
        const kindleQuotes = await fetchQuotesFromFirestore("clips")
        return kindleQuotes[random(kindleQuotes.length)]
    }
}

const KindleQuotes: FC = () => {
    const [kindleQuoteObject, setKindleQuoteObject] = useState(kindleQuoteTmp)
    useEffect(() => {
        fetchKindleQuotes().then((kindleQuote) => {
            if (kindleQuote) {
                setKindleQuoteObject(kindleQuote)
            }
        })
    }, [])

    return (
        <div className="text-center p-5">
            <h5>{kindleQuoteObject.book}</h5>

            <div className="fs-6">
                <p>
                    <i style={{ whiteSpace: "pre-wrap" }}>
                        {kindleQuoteObject.content}
                    </i>
                </p>
                <Link
                    to="/compose-kindle-quote"
                    state={{ quote: kindleQuoteObject }}
                >
                    Edit ✍🏼
                </Link>
            </div>
        </div>
    )
}

export default KindleQuotes
