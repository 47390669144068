// import { Timestamp } from "firebase/firestore"
import * as actions from "./logic/actionTypes"


interface Note {
    title: string,
    note: string,
    Timestamp: string,
    guid: string
}

interface KindleQuotes {
    book: string
    content: string
    position: string
    guid: string
}

interface IState {
    manifest_available?: {
        hasVocabStore?: boolean
        hasNotesStore?: boolean
    }
    manifest?: {
        vocabFileUrl?: string
        kindleQuotes?: [KindleQuotes]
        notes?: [Note]
    }
    user: {    
        initialised: boolean
        displayName?: string
        uid?: string
    }
}

const initialState: IState = {
    user: {
        initialised: false
    }
}


export default function reducer(state = initialState, action: any) {
    console.log("Reducer called: ", action.type)
    console.log("with payload: ", action.payload)

    switch (action.type) {
        case actions.USER_UPDATED:
            if (action.payload.user.uid) {
                return {
                    manifest: {
                        vocabFileUrl: `https://firebasestorage.googleapis.com/v0/b/hello-future-me-homepage.appspot.com/o/vocab-json%2F${action.payload.user.uid}-vocab.json?alt=media&token=c97fbb32-0c18-4512-9a6f-9affa94ed357`,
                        notes: state.manifest?.notes
                    },
                    user: {
                        initialised: true,
                        displayName: action.payload.user.displayName,
                        uid: action.payload.user.uid,
                    },
                    manifest_available: {
                        ...state.manifest_available
                    }
                }
            } else {
                return {
                    user: {
                        initialised: false
                    }
                }
            }
            
        case actions.USER_SIGNOUT:
            return {
                user: {
                    initialised: false
                }
            }

        case actions.VOCAB_STORE_AVAILABLE:
            return {
                user: {...state.user},
                manifest: {
                    ...state.manifest,
                },
                manifest_available: {
                    hasVocabStore: action.payload.manifest_available.hasVocabStore,
                    ...state.manifest_available
                }
            }

        case actions.NOTES_UPDATED:
            return {
                user: {...state.user},
                manifest: {
                    ...state.manifest,
                    notes: action.payload.manifest.notes
                },
                manifest_available: {
                    ...state.manifest_available
                }
            }

        case actions.KINDLE_QUOTES_UPDATED:
            return {
                user: {...state.user},
                manifest: {
                    ...state.manifest,
                    kindleQuotes: action.payload.manifest.kindleQuotes
                },
                manifest_available: {
                    ...state.manifest_available
                }
            }
    }
    return state
}