import * as actions from "./actionTypes"

export function userUpdate(uid: any, displayName: any) {
    return {
        type: actions.USER_UPDATED,
        payload: {
            user: {
                displayName: displayName,
                uid: uid,
                initialised: true
            }
        }
    }
}


export function userSignOut() {
    return {
        type: actions.USER_UPDATED,
        payload: {
            user: {
                initialised: false
            }
        }
    }
}


export function notesUpdate(newNotes: []) {
    return {
        type: actions.NOTES_UPDATED,
        payload: {
            manifest: {
                notes: newNotes
            }
        }
    }
}

export function kindleQuotesUpdate(kindleQuotes: []) {
    return {
        type: actions.KINDLE_QUOTES_UPDATED,
        payload: {
            manifest: {
                kindleQuotes: kindleQuotes
            }
        }
    }
}

export function hasVocabStore(b: boolean) {
    return {
        type: actions.VOCAB_STORE_AVAILABLE,
        payload: {
            manifest_available: {
                hasVocabStore: b
            }
        }
    }
}