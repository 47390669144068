import React, { useEffect, useState } from "react"
import { FC } from "react"
import "prismjs/themes/prism.css"

import { Button, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import {
    deleteKindleQuoteFromFirestore,
    fetchQuotesFromFirestore,
} from "../../logic/firestoreHelper"

interface KindleQuote {
    book: string
    content: string
    position: string
    guid: string
}

// const presentKindleQuote = (kindleQuote: KindleQuote) => {
//     return {
//         book: kindleQuote.book,
//         content: kindleQuote.content,
//         position: kindleQuote.position,
//         guid: kindleQuote.guid,
//     }
// }

const kindleQuoteTmp: KindleQuote = {
    book: "",
    content: "",
    position: "",
    guid: "",
}

const deleteHandler = async (
    kindleQuote: KindleQuote,
    setKindleQuoteObject: React.Dispatch<React.SetStateAction<KindleQuote[]>>
) => {
    console.log(`Deleting note ${kindleQuote.book}`)
    try {
        deleteKindleQuoteFromFirestore(kindleQuote)
    } catch (e) {
        console.error("Error deleting document: ", e)
    }

    await fetchQuotesFromFirestore("clips").then(
        (kindleQuotes: KindleQuote[]) => {
            setKindleQuoteObject(kindleQuotes)
        }
    )
}

const assembleKindleQuote = (
    kindleQuotes: KindleQuote[],
    setKindleQuoteObject: React.Dispatch<React.SetStateAction<KindleQuote[]>>
) => {
    const listOfNotes = kindleQuotes.map((quote: KindleQuote) => {
        return (
            <tr>
                <td>{quote.book}</td>
                <td>{quote.content}</td>
                <td>
                    <Link
                        to="/compose-kindle-quote"
                        state={{
                            quote: quote,
                        }}
                    >
                        Edit
                    </Link>
                </td>
                <td>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={() =>
                            deleteHandler(quote, setKindleQuoteObject)
                        }
                    >
                        ❌
                    </Button>
                </td>
            </tr>
        )
    })
    const tableDisplay = (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Note title</th>
                    <th scope="col">Note contents</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            {listOfNotes}
        </table>
    )

    return <div>{tableDisplay}</div>
}

const EditNotes: FC = () => {
    const [kindleQuoteObject, setKindleQuoteObject] = useState([kindleQuoteTmp])

    useEffect(() => {
        fetchQuotesFromFirestore("clips").then((kindleQuote: KindleQuote[]) => {
            setKindleQuoteObject(kindleQuote)
        })
    }, [])

    return (
        <Container className="container-sm px-5">
            {assembleKindleQuote(kindleQuoteObject, setKindleQuoteObject)}
        </Container>
    )
}

export default EditNotes
