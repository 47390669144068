import React from "react"
import { FC, useState, useEffect } from "react"
import { store } from "../../store"

export interface VocabProps {
    word: string
    definition: string
    context: string
    book: string
    author: string
}

const Vocab: FC = () => {
    store.subscribe(() => {})
    const fileUrl = store.getState().manifest?.vocabFileUrl

    const wordTmp = {
        word: "not initialised",
        definition: "not initialised",
        context: "not initialised",
        book: "not initialised",
        author: "not initialised",
    }

    const [wordObject, setWordObject] = useState(wordTmp)

    useEffect(() => {
        if (fileUrl) {
            const url = new URL(fileUrl)
            fetch(url.href, { mode: "cors" })
                .then((result) => {
                    if (!result.ok) {
                        throw Error(result.statusText)
                    }
                    return result.json()
                })
                .then((vocabList) => {
                    let random = Math.floor(
                        Math.random() * Math.floor(vocabList.length)
                    )
                    return vocabList[random]
                })
                .then((word: VocabProps) => {
                    console.log(`Setting word: ${word}`)
                    setWordObject(word)
                })
                .catch((error) => {
                    console.log("Request failed", error)
                })
        }
    }, [fileUrl])

    return (
        <div className="text-center p-5">
            <h5>{wordObject.word}</h5>
            <p>{wordObject.definition}</p>

            <div className="fs-6">
                <p>
                    <i>"{wordObject.context}"</i>
                </p>
                <p>
                    From {wordObject.book} by {wordObject.author}
                </p>
            </div>
        </div>
    )
}

export default Vocab
