import { store } from "../store"
import { hasVocabStore } from "./actions"

export const detectVocabStoreAvailable = (fileUrl: string | undefined) => {
    if (fileUrl) {
        const url = new URL(fileUrl)
        fetch(url.href, { mode: "cors" })
            .then((result) => {
                if (!result.ok) {
                    console.log("No vocab availble, marking hasVocabStore as false")
                    store.dispatch(hasVocabStore(false))
                }
                store.dispatch(hasVocabStore(true))
            })
            .catch((error) => {
                console.log("Request failed", error)
            })
    } else {
        store.dispatch(hasVocabStore(false))
    }
}
