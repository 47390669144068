import React, { useState } from "react"
import { FC } from "react"
import "prismjs/themes/prism.css"
import loading from "../../pretty/Rolling-1s-200px.svg"

import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { Container, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import {
    newNoteObject,
    upsertDocumentToFirestore,
} from "../../logic/firestoreHelper"

const ComposeNote: FC = () => {
    let editMode = false
    const location = useLocation()

    const populateNote = () => {
        try {
            const existingNote = location.state.note
            console.log(location)
            if (existingNote) {
                editMode = true
                return existingNote
            }
        } catch {
            return {
                title: "",
                contents: "",
            }
        }
    }

    const note = populateNote()

    const [status, setFormStatus] = useState({
        type: "waiting for input",
    })

    const formHandler = async () => {
        setFormStatus({ type: "submitting" })
        const formTitleValue = (
            document.getElementById("formNoteTitle") as HTMLInputElement
        ).value

        const formNoteContents = (
            document.getElementById("formNoteContents") as HTMLInputElement
        ).value
        try {
            if (formTitleValue && formNoteContents) {
                console.log("Valid form input")

                const newNote = newNoteObject(formTitleValue, formNoteContents)

                if (!editMode) {
                    await upsertDocumentToFirestore(newNote, undefined)
                    setFormStatus({ type: "newNoteSuccess" })
                } else {
                    await upsertDocumentToFirestore(
                        newNote,
                        location.state.note.guid
                    )
                    setFormStatus({ type: "editSuccess" })
                }
            } else {
                throw Error("Note title and contents are missing")
            }
        } catch (e) {
            setFormStatus({ type: "error" })
            console.error("Error adding document: ", e)
        }
    }

    return (
        <Container className="text-center col-8">
            <Form>
                <Form.Group className="mb-3" controlId="formNoteTitle">
                    <Form.Label>Note Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Note title"
                        autoComplete="off"
                        defaultValue={note.title}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formNoteContents">
                    <Form.Label>Note Contents</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Note contents"
                        as="textarea"
                        rows={3}
                        autoComplete="off"
                        defaultValue={note.contents}
                    />
                </Form.Group>
                <Button variant="primary" type="button" onClick={formHandler}>
                    Submit
                </Button>
            </Form>

            <Row className="p-3">
                {status?.type === "submitting" && (
                    <p>
                        <img
                            src={loading}
                            alt="loading..."
                            className="img-icon"
                            style={{ height: "32px" }}
                        />
                    </p>
                )}
                {status?.type === "newNoteSuccess" && <p>Note added ✅</p>}
                {status?.type === "editSuccess" && <p>Note edited ✅</p>}
                {status?.type === "error" && (
                    <p>😥 We couldn't add your note. Try again.</p>
                )}
            </Row>
        </Container>
    )
}

export default ComposeNote
