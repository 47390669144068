import React, { useState } from "react"
import { FC } from "react"
import "prismjs/themes/prism.css"
import loading from "../../pretty/Rolling-1s-200px.svg"

import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { Container, Row } from "react-bootstrap"
import { useLocation } from "react-router-dom"
import { upsertKindleQuoteToFirestore } from "../../logic/firestoreHelper"

const ComposeKindleQuote: FC = () => {
    let editMode = false
    const location = useLocation()

    const populateKindleQuote = () => {
        try {
            const existingQuote = location.state.quote
            console.log(location)
            if (existingQuote) {
                editMode = true
                return existingQuote
            }
        } catch {
            return {
                book: "",
                content: "",
                guid: "",
                position: "",
            }
        }
    }

    const [kindleQuote, setKindleQuote] = useState(populateKindleQuote())

    const [status, setFormStatus] = useState({
        type: "waiting for input",
    })

    const formHandler = async () => {
        setFormStatus({ type: "submitting" })
        const formBookValue = (
            document.getElementById("formKindleQuoteBook") as HTMLInputElement
        ).value

        const formKindleQuoteContent = (
            document.getElementById(
                "formKindleQuoteContent"
            ) as HTMLInputElement
        ).value
        try {
            if (formBookValue && formKindleQuoteContent) {
                console.log("Valid form input")

                const newKindleQuote = {
                    book: formBookValue,
                    content: formKindleQuoteContent,
                    position: kindleQuote.position ? kindleQuote.position : 0,
                    guid: kindleQuote.guid,
                }

                // if (!editMode) {
                //     await upsertDocumentToFirestore(newKindleQuote, undefined)
                //     setFormStatus({ type: "newNoteSuccess" })
                // } else {
                await upsertKindleQuoteToFirestore(kindleQuote, newKindleQuote)
                setKindleQuote(newKindleQuote)
                setFormStatus({ type: "editSuccess" })
                // }
            } else {
                throw Error("Note title and contents are missing")
            }
        } catch (e) {
            setFormStatus({ type: "error" })
            console.error("Error adding document: ", e)
        }
    }

    return (
        <Container className="text-center col-8">
            <Form>
                <Form.Group className="mb-3" controlId="formKindleQuoteBook">
                    <Form.Label>Kindle Quote Book Title</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Kindle quote book title"
                        autoComplete="off"
                        defaultValue={kindleQuote.book}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formKindleQuoteContent">
                    <Form.Label>Note Contents</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Kindle quote contents"
                        as="textarea"
                        rows={3}
                        autoComplete="off"
                        defaultValue={kindleQuote.content}
                    />
                </Form.Group>
                <Button variant="primary" type="button" onClick={formHandler}>
                    Submit
                </Button>
            </Form>

            <Row className="p-3">
                {status?.type === "submitting" && (
                    <p>
                        <img
                            src={loading}
                            alt="loading..."
                            className="img-icon"
                            style={{ height: "32px" }}
                        />
                    </p>
                )}
                {status?.type === "newNoteSuccess" && <p>Note added ✅</p>}
                {status?.type === "editSuccess" && <p>Note edited ✅</p>}
                {status?.type === "error" && (
                    <p>😥 We couldn't add your note. Try again.</p>
                )}
            </Row>
        </Container>
    )
}

export default ComposeKindleQuote
