import React from "react"
import "./scss/custom.scss"
import "./App.css"
import Navbar from "react-bootstrap/Navbar"
import Row from "react-bootstrap/Row"
import Container from "react-bootstrap/Container"
import { store } from "./store"

import SignIn from "./components/login/SignIn"
import SignOut from "./components/login/SignOut"
import LoggedInProfileBadge from "./components/login/LoggedInProfileBadge"
import VocabUpload from "./components/upload/VocabUpload"
import ClippingsUpload from "./components/upload/ClippingsUpload"
import ComposeNote from "./components/upload/ComposeNote"
import "./logic/authHelper"
import { Link, Route, Routes, useNavigate } from "react-router-dom"
import Manifest from "./components/display/Manifest"
import { detectVocabStoreAvailable } from "./logic/vocabHelper"
import EditNotes from "./components/upload/EditNotes"
import { fetchQuotesFromFirestore } from "./logic/firestoreHelper"
import EditKindleQuotes from "./components/upload/EditKindleQuotes"
import ComposeKindleQuote from "./components/upload/ComposeKindleQuote"
import Splash from "./components/display/Splash"

function App() {
    store.subscribe(() => {
        console.log("store changed:", store.getState())
    })

    const user = store.getState().user.initialised

    const navigate = useNavigate()

    const refreshPage = () => {
        navigate(0)
    }

    const vocabFileUrl = store.getState().manifest?.vocabFileUrl
    detectVocabStoreAvailable(vocabFileUrl)
    fetchQuotesFromFirestore("clips")

    return (
        <div className="App">
            <div className="container-fluid first-block">
                <Row className="justify-content-end col-lg-9 col-md-10 mx-auto">
                    {user ? (
                        <Container>
                            <Row>
                                <LoggedInProfileBadge />
                            </Row>
                            <Row>
                                <Routes>
                                    <Route index element={<Manifest />} />
                                    <Route
                                        path="/upload-vocab-db"
                                        element={<VocabUpload />}
                                    />
                                    <Route
                                        path="/upload-clippings"
                                        element={<ClippingsUpload />}
                                    />
                                    <Route
                                        path="/compose-note"
                                        element={<ComposeNote />}
                                    />
                                    <Route
                                        path="/compose-kindle-quote"
                                        element={<ComposeKindleQuote />}
                                    />
                                    <Route
                                        path="/edit-notes"
                                        element={<EditNotes />}
                                    />
                                    <Route
                                        path="/edit-kindle-quotes"
                                        element={<EditKindleQuotes />}
                                    />
                                </Routes>
                            </Row>
                        </Container>
                    ) : (
                        <Routes>
                            <Route index element={<Splash />} />
                        </Routes>
                    )}

                    <Routes>
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="/signout" element={<SignOut />} />
                    </Routes>

                    <Navbar fixed="bottom">
                        <Container>
                            <div className="text-center col-12">
                                <span
                                    className="d-block the-title display-6"
                                    onClick={refreshPage}
                                >
                                    <Link to="/">👋 Hello Future Me</Link>
                                </span>
                                {user ? (
                                    <span>
                                        <span className="d-block">
                                            <Link to="/compose-note">
                                                Compose note ✍🏼
                                            </Link>
                                        </span>
                                        <span className="d-block">
                                            <Link to="/edit-notes">
                                                Edit notes 📑
                                            </Link>
                                        </span>
                                        <span className="d-block">
                                            <Link to="/upload-vocab-db">
                                                Upload from Kindle: vocab.db 📤
                                            </Link>
                                        </span>
                                        <span className="d-block">
                                            <Link to="/upload-clippings">
                                                Upload from Kindle: My
                                                Clippings.txt 📤
                                            </Link>
                                        </span>
                                        <span className="d-block">
                                            <Link to="/edit-kindle-quotes">
                                                Edit kindle quotes 📑
                                            </Link>
                                        </span>
                                    </span>
                                ) : (
                                    <span />
                                )}
                            </div>
                        </Container>
                    </Navbar>
                </Row>
            </div>
        </div>
    )
}

export default App
