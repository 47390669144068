import React, { useEffect, useState } from "react"
import { FC } from "react"
import "prismjs/themes/prism.css"

import { Button, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import {
    deleteElementFromFirestore,
    fetchFromFirestore,
} from "../../logic/firestoreHelper"

interface INote {
    title: string
    note: string
    Timestamp: string
    guid: string
}

const presentNote = (note: INote) => {
    return {
        title: note.title,
        Timestamp: note.Timestamp,
        contents: note.note,
        guid: note.guid,
    }
}

const noteTmp: INote = {
    title: "",
    note: "",
    Timestamp: "",
    guid: "",
}

const deleteHandler = async (
    guid: string,
    setNotesObject: React.Dispatch<React.SetStateAction<INote[]>>
) => {
    console.log(`Deleting note ${guid}`)
    try {
        deleteElementFromFirestore(guid)
    } catch (e) {
        console.error("Error deleting document: ", e)
    }

    await fetchFromFirestore("notes").then((notes: INote[]) => {
        setNotesObject(notes)
    })
}

const assembleNotes = (
    notes: INote[],
    setNotesObject: React.Dispatch<React.SetStateAction<INote[]>>
) => {
    const listOfNotes = notes.map((note: INote) => {
        return (
            <tr>
                <td>{note.title}</td>
                <td>{note.note}</td>
                <td>
                    <Link
                        to="/compose-note"
                        state={{ note: presentNote(note) }}
                    >
                        Edit
                    </Link>
                </td>
                <td>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={() =>
                            deleteHandler(
                                presentNote(note).guid,
                                setNotesObject
                            )
                        }
                    >
                        ❌
                    </Button>
                </td>
            </tr>
        )
    })
    const tableDisplay = (
        <table className="table">
            <thead>
                <tr>
                    <th scope="col">Note title</th>
                    <th scope="col">Note contents</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                </tr>
            </thead>
            {listOfNotes}
        </table>
    )

    return <div>{tableDisplay}</div>
}

const EditNotes: FC = () => {
    const [notesObject, setNotesObject] = useState([noteTmp])

    useEffect(() => {
        fetchFromFirestore("notes").then((notes: INote[]) => {
            setNotesObject(notes)
        })
    }, [])

    return (
        <Container className="container-sm px-5">
            {assembleNotes(notesObject, setNotesObject)}
        </Container>
    )
}

export default EditNotes
