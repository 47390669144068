import React from "react"
import { FC, useState, useEffect } from "react"
import { store } from "../../store"
import { getFirestore } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { enableIndexedDbPersistence } from "firebase/firestore"
import { Link } from "react-router-dom"
import { fetchFromFirestore } from "../../logic/firestoreHelper"

const firebaseConfig = {
    apiKey: "AIzaSyDSC8brrcgiD6TCKs753m3uFoDjGX_rlWs",
    authDomain: "hello-future-me-homepage.firebaseapp.com",
    projectId: "hello-future-me-homepage",
    storageBucket: "hello-future-me-homepage.appspot.com",
    messagingSenderId: "84001985059",
    appId: "1:84001985059:web:1853f1afd96b5cdc939796",
    measurementId: "G-JKEPR5P0EJ",
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

enableIndexedDbPersistence(db).catch((err) => {
    if (err.code === "failed-precondition") {
    } else if (err.code === "unimplemented") {
    }
})

store.subscribe(() => {})

interface INote {
    title: string
    note: string
    Timestamp: string
    guid: string
}

const noteTmp = {
    title: "",
    Timestamp: "",
    contents: "",
    guid: "",
}

const presentNote = (note: INote) => {
    return {
        title: note.title,
        Timestamp: note.Timestamp,
        contents: note.note,
        guid: note.guid,
    }
}

const random = (length: number) => {
    const r = Math.floor(Math.random() * Math.floor(length))
    console.log("Picking random note index: ", r)
    return r
}

const fetchNote = async () => {
    try {
        const notes = store.getState().manifest?.notes
        console.log("fetchNote")
        console.log(notes)
        if (notes) {
            console.log("Fetching from perisisted notes store")
            return presentNote(notes[random(notes.length)])
        } else {
            console.log("???")
            const notes = await fetchFromFirestore("notes")
            return presentNote(notes[random(notes.length)])
        }
    } catch {
        console.log("Notes not initialised. Fetching notes")
        const notes = await fetchFromFirestore("notes")
        return presentNote(notes[random(notes.length)])
    }
}

const Note: FC = () => {
    const [noteObject, setNoteObject] = useState(noteTmp)
    useEffect(() => {
        fetchNote().then((note) => {
            if (note) {
                setNoteObject(note)
            }
        })
    }, [])

    return (
        <div className="text-center p-5">
            <h5>{noteObject.title}</h5>

            <div className="fs-6">
                <p>
                    <i style={{ whiteSpace: "pre-wrap" }}>
                        "{noteObject.contents}"
                    </i>
                </p>
                <Link to="/compose-note" state={{ note: noteObject }}>
                    Edit ✍🏼
                </Link>
            </div>
        </div>
    )
}

export default Note
