import { FC } from "react"
import { Container } from "react-bootstrap"
import { Link } from "react-router-dom"

const Splash: FC = () => {
    return (
        <Container>
            <Link to="/signin">Sign In 👩🏻‍💻</Link>

            <span className="d-block the-title display-4 p-5">
                👋 Hello Future Me
            </span>

            <p className="lead">
                Hello Future Me delivers your curated and personalized selection
                of book quotes, vocabulary, and notes through a chrome
                extension. (iOS & Android to come soon!)
            </p>

            <div className="card">
                <div className="card-body">
                    <Container>
                        <span className="text-primary the-title display-6">
                            👉 Here's how to get started:
                        </span>
                        <ol className="left">
                            <li>
                                <Link to="/signin">
                                    Set up your new account
                                </Link>{" "}
                                here!
                            </li>
                            <li>Add your personal quotes</li>
                            <li>Add your kindle vocabulary and kindle notes</li>

                            <li>
                                Install our Chrome extension and keep reading!
                            </li>
                        </ol>
                        <span className="text-success the-title display-6">
                            🌟 Our future goals include:
                        </span>
                        <ul className="left">
                            <li>
                                Expanding our quote and vocabulary database for
                                endless inspiration.
                            </li>
                            <li>
                                Adding social features to connect and share with
                                friends.
                            </li>
                            <li>
                                Offering personalized recommendations based on
                                your interests. #yourAImentor
                            </li>
                            <li>
                                Introducing mobile apps for iOS and Android.
                            </li>
                        </ul>
                        <span className="text-danger the-title display-6">
                            ✨
                        </span>
                        <p className="font-italic">
                            At Hello Future Me, we believe that every quote and
                            word can be a seed for inspiration and growth. We're
                            dedicated to fueling your curiosity, brightening
                            your day, and helping you become the best version of
                            yourself.
                        </p>
                        <p className="text-info">
                            <Link to="/signin">
                                Join us on this exciting journey and let's
                                create a brighter future together! ✨
                            </Link>
                        </p>
                    </Container>
                </div>
            </div>
        </Container>
    )
}

export default Splash
