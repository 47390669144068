import { FC, useState } from "react"
import { initializeApp } from "firebase/app"
import { getAuth, signOut } from "firebase/auth"

import { store } from "../../store"
import { userSignOut } from "../../logic/actions"
import { Container, Row } from "react-bootstrap"

const firebaseConfig = {
    apiKey: "AIzaSyDSC8brrcgiD6TCKs753m3uFoDjGX_rlWs",
    authDomain: "hello-future-me-homepage.firebaseapp.com",
    projectId: "hello-future-me-homepage",
    storageBucket: "hello-future-me-homepage.appspot.com",
    messagingSenderId: "84001985059",
    appId: "1:84001985059:web:1853f1afd96b5cdc939796",
    measurementId: "G-JKEPR5P0EJ",
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

const SignOut: FC = () => {
    const [status, setSignedOutStatus] = useState({
        type: "Signing out...",
    })

    signOut(auth)
        .then(() => {
            // Sign-out successful.
            store.dispatch(userSignOut())
            setSignedOutStatus({ type: "loggedOut" })
            console.log("successfully logged out")
        })
        .catch((error) => {
            // An error happened.
            setSignedOutStatus({ type: "loggedOutFailure" })
            console.log("error logging out")
            console.log(error)
        })
    return (
        <Container>
            <Row className="p-3">
                {status?.type === "loggedOut" && (
                    <p>Successfully logged out ✅</p>
                )}
                {status?.type === "loggedOutFailure" && (
                    <p>Error logging you out ☹️</p>
                )}
            </Row>
        </Container>
    )
}

export default SignOut
