import React, { useMemo, useCallback, useState } from "react"
import { FC } from "react"
import { Container } from "react-bootstrap"
import "prismjs/themes/prism.css"
import { useDropzone } from "react-dropzone"
import { getStorage, ref, uploadBytes } from "firebase/storage"
import { store } from "../../store"
import { initializeApp } from "firebase/app"

const firebaseConfig = {
    apiKey: "AIzaSyDSC8brrcgiD6TCKs753m3uFoDjGX_rlWs",
    authDomain: "hello-future-me-homepage.firebaseapp.com",
    projectId: "hello-future-me-homepage",
    storageBucket: "hello-future-me-homepage.appspot.com",
    messagingSenderId: "84001985059",
    appId: "1:84001985059:web:1853f1afd96b5cdc939796",
    measurementId: "G-JKEPR5P0EJ",
}

const app = initializeApp(firebaseConfig)

const storage = getStorage(app, "gs://hello-future-me-homepage-clippings")

const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    paddingWidth: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "solid",
    backgroundColor: "rgba(0,0,0,0.3)",
    color: "#bdbdbd",
    transition: "border .24s ease-in-out",
    height: "50vh",
}

const focusedStyle = {
    borderColor: "#f8c414",
}

const acceptStyle = {
    borderColor: "#f8c414",
}

const rejectStyle = {
    borderColor: "#f84e2a",
}

const getUserUID = () => {
    return store.getState().user.uid
}

const uploadFile = (file: File) => {
    const storageRef = ref(storage, `${getUserUID()}-clippings.txt`)

    uploadBytes(storageRef, file).then((snapshot: any) => {
        console.log("Uploaded a blob or file!")
    })
}

const ClippingsUpload: FC = () => {
    const [files, setFiles] = useState([])
    const onDrop = useCallback(
        (files: any) => {
            setFiles(files)
            uploadFile(files[0])
            console.log(files)
        },
        [setFiles]
    )

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({ accept: { "text/plain": [".txt"] }, onDrop })

    const style = useMemo(
        () => ({
            ...baseStyle,
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    )

    return (
        <section className="container-fluid">
            <div {...getRootProps({ className: "dropzone", style })}>
                <input {...getInputProps()} />
                <Container>
                    <span className="text-center dropzone-text">
                        Waiting for your{" "}
                        <code className="language-html">My Clippings.txt</code>{" "}
                        file...
                    </span>
                </Container>
            </div>
        </section>
    )
}

export default ClippingsUpload
