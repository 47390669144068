import { FC } from "react"
import { Row } from "react-bootstrap"
import { Link } from "react-router-dom"
import { store } from "../../store"

const ProfileBadge: FC = () => {
    store.subscribe(() => {})
    const userDisplayName = store.getState().user.displayName

    return (
        <Row className="justify-content-end">
            <div id="user-badge" className="col-4">
                {userDisplayName}
                <div>
                    <Link to="/signout">Sign Out 🚪</Link>
                </div>
            </div>
        </Row>
    )
}

export default ProfileBadge
