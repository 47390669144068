import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { userUpdate, userSignOut } from "./actions"

import { store } from "./../store"

const firebaseConfig = {
    apiKey: "AIzaSyDSC8brrcgiD6TCKs753m3uFoDjGX_rlWs",
    authDomain: "hello-future-me-homepage.firebaseapp.com",
    projectId: "hello-future-me-homepage",
    storageBucket: "hello-future-me-homepage.appspot.com",
    messagingSenderId: "84001985059",
    appId: "1:84001985059:web:1853f1afd96b5cdc939796",
    measurementId: "G-JKEPR5P0EJ",
}

const app = initializeApp(firebaseConfig)

const auth = getAuth(app)

// https://firebase.google.com/docs/reference/js/v8/firebase.User
onAuthStateChanged(auth, (user) => {
    console.log("User changed", user)
    if (user) {
        store.dispatch(userUpdate(user.uid, user.displayName))
    } else {
        console.log("User signed out")
        store.dispatch(userSignOut())
    }
})