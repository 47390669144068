import { FC } from "react"
import Vocab from "./Vocab"
import Note from "./Note"
import KindleQuotes from "./KindleQuotes"
import { store } from "../../store"

const assembleManifest = (
    hasVocabStore: boolean | undefined,
    hasNotesStore: boolean | undefined,
    hasKindleQuotesStore: boolean | undefined
) => {
    let elements = []

    if (hasVocabStore) {
        elements.push(<Vocab />)
    }
    if (hasNotesStore) {
        elements.push(<Note />)
    }
    if (hasKindleQuotesStore) {
        elements.push(<KindleQuotes />)
    }

    const choice = elements[Math.floor(Math.random() * elements.length)]
    if (choice === undefined) {
        return <div>idk lol 🤷‍♀️</div>
    }

    return choice
}

const Manifest: FC = () => {
    const hasVocabStore = store.getState().manifest_available?.hasVocabStore
    const hasNotesStore = store.getState().manifest?.notes ? true : false
    const hasKindleQuotesStore = store.getState().manifest?.kindleQuotes
        ? true
        : false

    return (
        <div>
            {assembleManifest(
                hasVocabStore,
                hasNotesStore,
                hasKindleQuotesStore
            )}
        </div>
    )
}

export default Manifest
