import { initializeApp } from "firebase/app";
import { arrayRemove, arrayUnion, deleteField, doc, getDoc, getDocFromCache, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid"
import { store } from "../store";
import { notesUpdate, kindleQuotesUpdate } from "./actions";

interface Datas {
    guid: {
        note: string
        title: string
        Timestamp: string
        guid: string
    }
}

interface KindleQuote {
    book: string
    content: string
    position: string
    guid: string
}

const firebaseConfig = {
    apiKey: "AIzaSyDSC8brrcgiD6TCKs753m3uFoDjGX_rlWs",
    authDomain: "hello-future-me-homepage.firebaseapp.com",
    projectId: "hello-future-me-homepage",
    storageBucket: "hello-future-me-homepage.appspot.com",
    messagingSenderId: "84001985059",
    appId: "1:84001985059:web:1853f1afd96b5cdc939796",
    measurementId: "G-JKEPR5P0EJ",
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

store.subscribe(() => {})

const getUserUID = () => {
    return store.getState().user.uid
}

export function newNoteObject(title: string, contents: string) {
    return {
        Timestamp: new Date(),
        title: title,
        note: contents
    }
}

export function persistData(notes: any) {
    console.log("Persisting data")
    store.dispatch(
        notesUpdate(
            notes.map((note: any) => ({
                title: note.title,
                note: note.note,
                Timestamp: note.Timestamp.toString(),
                guid: note.guid,
            }))
        )
    )
}

export function persistKindleQuotes(kindleQuotes: any) {
    console.log("Persisting kindleQuotes")
    store.dispatch(
        kindleQuotesUpdate(
            kindleQuotes.map((quote: any) => ({
                book: quote.book,
                content: quote.content,
                position: quote.position,
            }))
        )
    )
}

export async function fetchFromFirestore(collection: string) {
    const userUID = getUserUID()

    if (userUID) {
        const documentRef = doc(db, collection, userUID)
        const snapshot = await getDoc(documentRef)

        if (snapshot.exists()) {
            const asdf = async () => {
                return snapshot.data()
            }
            
            const stuff = await asdf().then((d: any) => {
                return d as Datas
            })
            let notes = []

            for (let [key, value] of Object.entries(stuff as Datas)) {
                notes.push({
                    guid: key,
                    ...value,
                })
            }
            console.log("Data: ", notes)
            persistData(notes)
            return notes
        }
        throw Error("Document doesn't exist")

    }
    throw Error("No userUID")
}

export async function fetchQuotesFromFirestore(collection: string) {
    const userUID = getUserUID()

    if (userUID) {
        const documentRef = doc(db, collection, userUID)
        const snapshot = await getDoc(documentRef)

        if (snapshot.exists()) {
            const kindleQuotes: KindleQuote[] = snapshot.get(`clips`)
            persistKindleQuotes(kindleQuotes)
            return kindleQuotes
        }
        throw Error("Document doesn't exist")
    }
    throw Error("No userUID")
}

async function fetchDocument(collection: string)  {
    const userUID = getUserUID()
    
    if (userUID) {
        const documentRef = doc(db, collection, userUID)
        try {
            console.log("Loaded notes from cache");
            const cachedSnap =  await getDocFromCache(documentRef);
            return { ref: documentRef, snap: cachedSnap};
        } catch (e) {
            console.log("Error fetching from cache: ", e);
            const fetchedSnap = await getDoc(documentRef)
            console.log("Loaded notes from remote");
            return { ref: documentRef, snap: fetchedSnap};
        }
    } else {
        throw Error("Couldn't fetch snapshot")
    }
}

export async function upsertDocumentToFirestore(newNote: { Timestamp: Date; title: string; note: string }, id: string | undefined) {
    const document = await fetchDocument("notes")
    const guid = id===undefined ? uuidv4() : id;

    if (!document.snap.exists()) {
        console.log("Document doesn't exist, creating document")
        setDoc(document.ref, {
            [`${guid}`]: { newNote },
        }).then((_) => {
            console.log("Document created")
        }).catch ((_) => {
            throw Error("Document failed to create")
        })
    }
    console.log("Updating document")
    updateDoc(document.ref, {
        [`${guid}`]: newNote,
    }).then((_) => {
        console.log("Document updated")
    }).catch ((_) => {
        throw Error("Document failed to update")
    })

    fetchFromFirestore("notes")
}

export async function upsertKindleQuoteToFirestore(oldKindleQuote: KindleQuote, newKindleQuote: KindleQuote) {
    const document = await fetchDocument("clips")

    // if (!document.snap.exists()) {
    //     console.log("Document doesn't exist, creating document")
    //     setDoc(document.ref, {
    //         clips: {
    //             [`${guid}`]: { newKindleQuote },
    //         }
    //     }).then((_) => {
    //         console.log("Document created")
    //     }).catch ((_) => {
    //         throw Error("Document failed to create")
    //     })
    // } else {
    console.log("Updating document", newKindleQuote)
    updateDoc(document.ref, {
        [`clips`]: arrayUnion(newKindleQuote),
    }).then((_) => {
        console.log("Document updated")
    }).catch ((_) => {
        throw Error("Document failed to update")
    })
    console.log("Removing original quote")

    updateDoc(document.ref, {
        [`clips`]: arrayRemove(oldKindleQuote),
    }).then((_) => {
        console.log("Document updated")
    }).catch ((_) => {
        throw Error("Document failed to update")
    })
    // }
    fetchFromFirestore("clips")
}

export async function deleteKindleQuoteFromFirestore(kindleQuote: KindleQuote) {
    const document = await fetchDocument("clips")
    updateDoc(document.ref, {
        [`clips`]: arrayRemove(kindleQuote),
    }).then((_) => {
        console.log("Document updated")
    }).catch ((_) => {
        throw Error("Document failed to update")
    })
    fetchFromFirestore("clips")
}


export async function deleteElementFromFirestore(id: string) {
    const document = await fetchDocument("notes")

    if (!document.snap.exists()) {
        console.log("Document doesn't exist")
    }
    console.log("Deleting document")
    updateDoc(document.ref, {
        [`${id}`]: deleteField(),
    }).then((_) => {
        console.log("Document deleted note element")
    }).catch ((_) => {
        throw Error("Document failed to update")
    })

    fetchFromFirestore("notes")
}